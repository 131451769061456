<template>
    <div class="approval-response">
        <b-form-group label="Comments">
            <b-form-textarea rows="5" v-model="comments">

            </b-form-textarea>
        </b-form-group>
        <b-row>
            <b-col cols="6">
                <b-button variant="success" block @click="approve"><CheckIcon /> Approve</b-button>
            </b-col>
            <b-col cols="6">
                <b-button variant="warning" block @click="reject"><CloseIcon /> Return</b-button>
            </b-col>
        </b-row>


    </div>
</template>

<script>
    import CheckIcon from 'vue-material-design-icons/Check';
    import CloseIcon from 'vue-material-design-icons/Close';

    export default {
        name: "ApprovalResponse",
        components: {CheckIcon, CloseIcon},
        data() {
            return {
                comments: ''
            }
        },
        methods: {
            approve() {
                this.$emit('approve', this.comments);
            },
            reject() {
                this.$emit('reject', this.comments);
            },
            reset() {
                this.comments = '';
            }
        }
    }
</script>

<style scoped lang="scss">
    div.approval-response {
        border-top: 1px solid $gray-300;
        padding-top: 10px
    }
</style>